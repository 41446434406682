import {
  faAngleLeft,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TitleDivider } from '@grimme/components';
import Button from '@grimme/ui-components/button';
import { Container, Stack, Typography } from '@mui/material';
import { Job } from 'hooks/types/jobs';
import { useRouterQueryFilters } from 'hooks/useRouterQueryFilters/useRouterQueryFilters';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Benefits, ButterBenefits } from '../benefits/benefits';
import { Contacts } from '../contacts';
import { JobFilterKeys } from '../jobs-filter/types';
import { translateLocationId } from '../jobs-list/chips/utils';
import { YoutubeTeaser } from '../youtube-teaser';
import { JobInfoStack } from './job-info-stack';
import { Root, classes } from './styles';
import { formatHtml, videoData } from './utils';

type JobLayoutProps = {
  butterBenefits: ButterBenefits;
  companyText: { company: string; logo: string; text: string; url: string };
  contacts: { subtitle: string; title: string };
  jobData: Job;
  videoData: videoData;
};

export function JobLayout(props: JobLayoutProps) {
  const { companyText, butterBenefits, contacts, jobData, videoData } = props;
  const { t } = useTranslation();
  const { getQueryParam } = useRouterQueryFilters();
  const router = useRouter();
  const [formattedHtml, setFormattedHtml] = useState<JSX.Element[]>(null);

  useEffect(() => {
    const formattedHtml = formatHtml(jobData.description, jobData.footer);
    setFormattedHtml(formattedHtml);
  }, [jobData]);

  const back = t('myGRIMME_group_backtooverview', 'Zurück zur Übersicht');
  const applyNowButtonLabel = t('myGRIMME_group_applynow', 'Jetzt bewerben');

  const fields = jobData && [
    {
      data: jobData.categories.map((cat) => cat.name),
      label: t('myGRIMME_group_categories', 'Karrierelevel'),
    },
    {
      data: jobData.functions.map((func) => func.name),
      label: t('myGRIMME_group_functions', 'Funktionsbereich'),
    },
    {
      data: jobData.locations
        .map(
          (loc) =>
            `${loc.zipCode || ''} ${loc.city} ${translateLocationId(loc.id)}`,
        )
        .join(' | '),
      label: t('myGRIMME_group_location', 'Standort'),
    },
    {
      data: jobData.company.name,
      label: t('myGRIMME_group_company', 'Unternehmen'),
    },
  ];

  const hideBenefits =
    getQueryParam(JobFilterKeys.LOCATIONS) === ('POL' || 'NLD');

  const handleNavigation = () => {
    router.back();
  };
  return (
    <>
      <Root>
        {jobData && (
          <Container>
            <div className="flex items-center hover:text-primary">
              <FontAwesomeIcon icon={faAngleLeft} />
              <Button onClick={handleNavigation} variant="text">
                {back}
              </Button>
            </div>
            <Typography mb={3} mt={5} variant="h2">
              {jobData.title}
            </Typography>
            <Stack
              columnGap={3}
              direction="row"
              flexWrap={'wrap'}
              mb={3}
              rowGap={1}
            >
              {fields.map((field, index) => (
                <JobInfoStack key={index} {...field} />
              ))}
            </Stack>
            <TitleDivider className={classes.titleDivider} />
            <div className="relative grid grid-cols-1 lg:grid-cols-12 gap-y-5 gap-x-20">
              <div className="lg:col-span-8">{formattedHtml}</div>
              <div className="lg:col-span-4 lg:sticky top-20">
                {companyText && (
                  <div className="flex flex-col gap-6 rounded-lg bg-white border-2 p-6 border-grey-200 mb-10">
                    <div className="w-full">
                      <img alt={companyText.company} src={companyText.logo} />
                    </div>
                    <p className="g-typography-body2">{companyText.text}</p>
                    <div className="flex hover:text-primary">
                      <a
                        className="underline g-typograpy-text-link"
                        href={companyText.url}
                      >
                        {companyText.url}
                      </a>
                      <FontAwesomeIcon
                        className="my-auto ml-2"
                        icon={faArrowUpRightFromSquare}
                      />
                    </div>
                  </div>
                )}
                <YoutubeTeaser videoData={videoData} />
              </div>
              <div className="col-span-full">
                <Button asChild variant="primary">
                  <a href={jobData.applyUrl}>{applyNowButtonLabel}</a>
                </Button>
              </div>
            </div>
          </Container>
        )}
      </Root>
      <Contacts
        contactDetails={jobData.recruiters}
        externalRecruiterJobTitle={jobData.externalRecruiterJobTitle}
        subtitle={contacts.subtitle}
        title={contacts.title}
      />
      {!hideBenefits && (
        <Benefits
          butterBenefits={butterBenefits}
          companyId={jobData.company.id}
        />
      )}
    </>
  );
}
