import { Hero } from '@grimme/components';
import { styled } from '@mui/material';
import { TBreadcrumbs } from 'lib/butter-types';
import { THeroWithoutCard } from 'lib/butter-types/general/hero';
import BreadcrumbsWrapper from '../breadcrumbs-wrapper/breadcrumbs-wrapper';
import { ButterImage } from '../butter-image';

const Root = styled(ButterImage)(() => ({
  objectFit: 'contain',
}));

export type BasicHeroProps = {
  breadcrumbs: TBreadcrumbs;
  hero: THeroWithoutCard;
};

export default function BasicHero({
  breadcrumbs,
  hero: { background_image, subtitle, title },
}: BasicHeroProps) {
  const image = (
    <Root
      priority
      showPlaceholder
      alt=""
      layout="fill"
      quality={100}
      src={background_image}
    />
  );

  return (
    <Hero
      background={image}
      breadcrumbs={<BreadcrumbsWrapper {...breadcrumbs} />}
      subtitle={subtitle}
      title={title}
    />
  );
}
