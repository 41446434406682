export const setCookie = (
  name: string,
  value: string,
  expiryInSeconds?: number,
) => {
  const currentDate = new Date();
  const expiryUnitInSeconds = expiryInSeconds || 2592000; // Default is set to 30 days
  currentDate.setTime(currentDate.getTime() + expiryUnitInSeconds * 1000);

  const cookieString = `${name}=${encodeURIComponent(
    value,
  )}; expires=${currentDate.toString()}; path=/`;

  if (typeof window !== 'undefined') {
    if (process.env.NODE_ENV === 'production') {
      // Add domain in production
      document.cookie = `${cookieString}; domain=.grimme.com`;
    } else {
      document.cookie = cookieString;
    }
  }
};
