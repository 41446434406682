import { YouTubeEmbed } from '@next/third-parties/google';
import { videoData } from '../job-layout/utils';

type YoutubeTeaser = {
  videoData: videoData;
};

export function YoutubeTeaser(props: YoutubeTeaser) {
  const { videoData } = props;
  return (
    <div className="relative">
      <div className="w-full rounded-lg overflow-hidden">
        <YouTubeEmbed params="controls=2" videoid={videoData.videoId} />
      </div>
      <h2 className="g-typography-body1 mt-4 line-clamp-2">
        {videoData.title}
      </h2>
    </div>
  );
}
