import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Navbar as GrimmeNavbar,
  UserSettingsContent,
  UserSettingsMenu,
  Visibility,
} from '@grimme/components';
import { signIn, signOut, useSession } from '@grimme/next-grimme-auth';
import { environment } from 'environment';
import { useFormattedProfileData, useGeolocation } from 'hooks';
import { GlobalSettings } from 'lib';
import { useRouter } from 'next/router';
import { useRef, useState } from 'react';
import { DEFAULT_LOCALE } from 'utils';
import locales from '~/locales.json';
import { setCookie } from '~/utils/cookies';
import { UserGreeting } from './user-greeting';
import { UserMenu } from './user-menu';

export type NavbarProps = {
  greeting: GlobalSettings['greeting'];
  loggedInMenu: GlobalSettings['logged_in_menu'];
  loggedOutMenu: GlobalSettings['logged_out_menu'];
  navbarData: GlobalSettings['navbar'];
  navigationLinks: GlobalSettings['navigation_menu'];
  socialLinks: GlobalSettings['social_links'];
  topLinks: GlobalSettings['top_links'];
};

export default function Navbar(props: NavbarProps) {
  const {
    greeting,
    loggedInMenu,
    loggedOutMenu,
    navbarData,
    navigationLinks,
    socialLinks,
    topLinks,
  } = props;

  const { isLoading, isUserLoggedIn } = useFormattedProfileData();

  const mappedSocialLinks = socialLinks.links.map((socialLink) => ({
    icon: (
      <FontAwesomeIcon
        icon={
          {
            iconName: socialLink.icon,
            prefix: socialLink.icon_type,
          } as IconLookup
        }
      />
    ),
    url: socialLink.url,
  }));

  const mappedNavigationLinks = navigationLinks
    .map((l) => l.fields)
    .map((link) => ({
      icon: (
        <FontAwesomeIcon
          icon={
            {
              iconName: link.icon,
              prefix: link.icon_type,
            } as IconLookup
          }
        />
      ),
      label: link.label,
      subitems:
        link.sub_items.length === 0
          ? undefined
          : link.sub_items.map((subitem) => ({
              label: subitem.label,
              url: subitem.url,
              visibility: subitem.visibility as Visibility,
            })),
      url: link.url,
      visibility: 'both' as Visibility,
    }));

  const userIconRef = useRef<HTMLButtonElement>(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const router = useRouter();
  const { asPath, locale, pathname } = router;
  const { data: geolocationData } = useGeolocation(locale);

  const { status } = useSession();

  const onLanguageChange = (lang: string) => {
    const locale = lang ? lang : DEFAULT_LOCALE;
    router.push({ pathname }, asPath, {
      locale,
    });
    setCookie('NEXT_LOCALE', locale);
  };

  const handleOnRegistration = () => {
    router.push(`${environment.registrationLink}/?language=${locale}`);
  };

  return (
    <GrimmeNavbar
      additionalLinks={navbarData.additional_links}
      isAuthenticated={status == 'authenticated'}
      links={mappedNavigationLinks}
      onLogoClick={() => {
        window.location.href = environment.grimmeWebsiteUrl;
      }}
      socialLinks={mappedSocialLinks}
      topLinks={topLinks.links}
      topLinksLabel={topLinks.title}
    >
      <UserGreeting
        greeting={greeting}
        ref={userIconRef}
        setIsShowingUserSettings={setUserMenuOpen}
      />
      <UserSettingsMenu
        anchorEl={userIconRef.current}
        onBackdropClick={() => setUserMenuOpen(false)}
        open={userMenuOpen}
      >
        <UserSettingsContent
          geolocation={geolocationData}
          isLoading={isLoading}
          isSessionValid={isUserLoggedIn}
          isShowingContact={false}
          MenuComponent={
            <UserMenu
              loggedInMenu={loggedInMenu}
              loggedOutMenu={loggedOutMenu}
            />
          }
          onClose={() => setUserMenuOpen(false)}
          onHideContact={() => setUserMenuOpen(false)}
          onLanguageChange={onLanguageChange}
          onLogin={() => signIn()}
          onLogout={() => signOut()}
          onRegister={handleOnRegistration}
          supportedLanguages={locales}
        />
      </UserSettingsMenu>
    </GrimmeNavbar>
  );
}
